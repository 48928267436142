import { useEffect, useState } from 'react'
import { TestAddon, TestInfo } from '../../gql'
import { cn } from '../../helpers/tailwind'
import { ProductDetailsFromState } from './use-product-details'
import Tick_Icon from '@/images/icons/tick_icon.svg?svgr'
import * as UI from '@/ui'

export type ProductAddOnsModalItemProps = {
  addOn: {
    testAddon: Omit<TestAddon, 'test'> & { test: Pick<TestInfo, 'friendlyName'> }
    why: string
  }
  productDetails: ProductDetailsFromState
}

const ProductAddOnsModalItem = ({ addOn, productDetails }: ProductAddOnsModalItemProps) => {
  const { state, updateTemporaryAddons } = productDetails
  const [selected, setSelected] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const saving = addOn.testAddon.standalonePrice
    ? Math.round((1 - addOn.testAddon.price / addOn.testAddon.standalonePrice) * 100)
    : undefined

  useEffect(() => {
    selected ? setIsOpen(true) : setIsOpen(false)
  }, [selected])

  useEffect(() => {
    const temporaryAddons = state.modal?.props.temporaryAddons
    if (temporaryAddons) {
      setSelected(temporaryAddons.has(addOn.testAddon.code))
    }
  }, [state.modal?.props.temporaryAddons, addOn.testAddon.code])

  const handleToggleAddon = (e: React.MouseEvent) => {
    e.stopPropagation() // Prevent triggering the dropdown
    setIsOpen(!isOpen)

    const temporaryAddons = state.modal?.props.temporaryAddons
    if (!temporaryAddons) return

    const newTemporaryAddons = new Set(temporaryAddons)
    if (newTemporaryAddons.has(addOn.testAddon.code)) {
      newTemporaryAddons.delete(addOn.testAddon.code)
    } else {
      newTemporaryAddons.add(addOn.testAddon.code)
    }

    updateTemporaryAddons(newTemporaryAddons)
    setSelected(!selected)
  }

  return (
    <button
      onClick={handleToggleAddon}
      className={cn(
        selected ? 'bg-selphGreen-400' : 'bg-selphWhite-300',
        'group w-full cursor-pointer rounded-lg px-2 py-3 hover:bg-selphGreen-400 sm:p-5',
      )}
    >
      {/* Header section - always visible */}
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center gap-3">
          {selected ? (
            <span className="flex size-5 shrink-0 items-center justify-center rounded-full border border-selphOrange-500 bg-selphOrange-500 p-0.5 text-selphWhite-300">
              <Tick_Icon className="h-4" />
            </span>
          ) : (
            <span className="block size-5 shrink-0 rounded-full border border-selphOrange-500 bg-selphWhite-300 group-hover:bg-selphOrange-500" />
          )}

          <div className="flex flex-1 items-center justify-between gap-x-4">
            <UI.Paragraph
              className="text-left leading-none font-normal group-hover:text-selphWhite-500"
              size="medium"
              color={selected ? 'white' : 'black'}
            >
              {addOn.testAddon.test.friendlyName || addOn.testAddon.name}{' '}
              <span
                className={cn(selected ? 'text-selphWhite-500' : 'text-selphAmber-500 group-hover:text-selphWhite-500')}
              >
                {saving && `(save ${saving}%)`}
              </span>
            </UI.Paragraph>

            <div
              className={cn(
                selected ? 'text-selphWhite-500' : 'text-selphBlack group-hover:text-selphWhite-500',
                'flex flex-col items-center gap-x-2 font-normal',
              )}
            >
              {addOn.testAddon.standalonePrice && (
                <span className="line-through opacity-50">
                  <UI.Currency value={addOn.testAddon.standalonePrice} />
                </span>
              )}
              <span>
                <UI.Currency value={addOn.testAddon.price} />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Collapsible content section */}
      <div
        className={cn(
          isOpen ? 'max-h-48 opacity-100' : 'max-h-0 opacity-0',
          'overflow-hidden transition-[max-height,opacity] duration-300 ease-in-out',
        )}
      >
        <div className="ml-7">
          <UI.Block gap="xs">
            <UI.Markdown
              components={{
                p: ({ children }: { children: React.ReactNode }) => (
                  <UI.Paragraph size="small" color="white" className="text-left leading-snug">
                    {children}
                  </UI.Paragraph>
                ),
              }}
            >
              {addOn.why}
            </UI.Markdown>
          </UI.Block>
        </div>
      </div>
    </button>
  )
}

export default ProductAddOnsModalItem
