import { ProductType } from '../../gql'
import useUi from '../ui/use-ui'
import ProductLink from './product-link'
import RightArrowIcon from '@/images/icons/arrowRight_icon.svg?svgr'
import * as UI from '@/ui'

export interface RelatedProductProps {
  __typename?: string
  sku: string
  name: string
  type: ProductType
  slug: string
  description: string
  featuredImage: {
    src: string
    name: string
  }
}

export type RelatedProductCardColours = 'lightGrey' | 'darkGrey' | 'white'
export type RelatedProductCardProps = {
  product: RelatedProductProps
  colour?: RelatedProductCardColours
}

const colours = {
  lightGrey: 'bg-selphGrey-50',
  darkGrey: 'bg-selphGrey-100',
  white: 'bg-white',
}

const RelatedProductCard = ({ product, colour = 'white' }: RelatedProductCardProps) => {
  const ui = useUi({
    styles: {
      color: { options: colours, selected: colour },
    },
    name: 'Related Product Card',
  })

  return (
    <ProductLink color="none" slug={product.slug} type={product.type}>
      <div
        className={`${ui.className} group flex h-[544px] w-[340px] flex-col overflow-clip rounded-2xl px-6 filter transition-[filter] duration-300 hover:drop-shadow-[0_0_12px_rgba(0,0,0,0.15)]`}
      >
        <div className="relative mt-6 mb-4 h-64 w-72 flex-none overflow-hidden rounded-2xl">
          <UI.Image
            className="overflow-hidden rounded-2xl transition-transform duration-300 group-hover:scale-110"
            fill
            sizes="288px"
            src={`/images/product/${product.featuredImage.src}`}
            alt={product.featuredImage.name}
          />
        </div>

        <div className="flex flex-grow flex-col justify-between">
          <UI.Heading size="xs" weight="regular" className="mb-2 line-clamp-2 text-start" as="h3">
            {product.name}
          </UI.Heading>

          <UI.Paragraph className="line-clamp-3 text-start">{product.description}</UI.Paragraph>

          <span className="mb-6 flex w-full flex-wrap items-center justify-center gap-x-2 rounded-md bg-selphOrange-500 px-6 py-2.5 text-selphWhite-500 transition duration-200 group-hover:bg-selphAmber-500">
            Select <RightArrowIcon className="size-4" />
          </span>
        </div>
      </div>
    </ProductLink>
  )
}

export default RelatedProductCard
