import TrustpilotBox from '../Trustpilot/trustpilot-box'
import Image from '@/images//illustrations/privacyPolicyCombo.png'
import * as UI from '@/ui'

const PrivacyPromise = () => {
  return (
    <div className="flex w-full gap-10 max-md:flex-col">
      <UI.Block className="order-1 md:order-2 md:w-7/12">
        <UI.Heading size={{ default: 'medium', lg: 'large' }} className="max-w-xl lg:max-w-2xl">
          Our Privacy Promise
        </UI.Heading>

        <UI.Paragraph className="max-w-4xl" size={{ default: 'medium', lg: 'large' }}>
          Your health is yours. It&apos;s personal. That&apos;s why we&apos;re committed to keeping your data safe and
          secure. Here&apos;s how we do it:
        </UI.Paragraph>

        <UI.List listStyle="number" gap="medium" margin="large" className="text-lg font-light lg:text-xl">
          <UI.ListItem>We use anonymous packaging.</UI.ListItem>
          <UI.ListItem>Only the analyses you request are performed on your samples.</UI.ListItem>
          <UI.ListItem>Your GP won&apos;t see your results unless you want them to.</UI.ListItem>
          <UI.ListItem>Your data are safe from insurance companies.</UI.ListItem>
        </UI.List>

        <UI.Paragraph className="max-w-4xl" size={{ default: 'medium', lg: 'large' }}>
          See our{' '}
          <UI.Link to="privacy" type="text">
            Privacy Policy
          </UI.Link>{' '}
          and our{' '}
          <UI.Link to="terms-of-service" type="text">
            Terms of Service
          </UI.Link>{' '}
          <span>for more info.</span>{' '}
        </UI.Paragraph>

        <TrustpilotBox boxStyle="microHeader" />
      </UI.Block>

      <div className="relative order-2 mx-auto max-md:max-w-md md:order-1 md:w-5/12">
        <UI.Image src={Image} height="380" alt="General combo image" />
      </div>
    </div>
  )
}

export default PrivacyPromise
