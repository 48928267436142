import { marked } from 'marked'
import { useMemo } from 'react'
import { buildSectionId } from '../content-block/side-navigation'
import { productStaticSectionsIds } from '../../pages/tests/[slug].page'
import * as UI from '@/ui'

export type ProductFaqBlockProps = {
  header?: string | null
  items: {
    question: string
    answer: string
  }[]
}

export const ProductFaqBlock = ({ items, header }: ProductFaqBlockProps) => {
  const accordionItems: UI.AccordionProps['items'] = useMemo(
    () =>
      items.map(({ question, answer }) => ({
        title: question,
        content: [
          <UI.Block key={0}>
            <UI.Markdown>{answer}</UI.Markdown>
          </UI.Block>,
        ],
      })),
    [items],
  )

  if (items.length === 0) return null

  const jsonLdData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: items.map(({ question, answer }) => ({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: marked.parse(answer),
      },
    })),
  }

  return (
    <>
      <script
        id="faqJsonLd"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
      />

      <UI.Block className="mx-auto w-full max-w-3xl py-8" id={buildSectionId(productStaticSectionsIds.faqs)}>
        <UI.Heading
          size={{ default: 'medium', lg: 'large' }}
          as="h2"
          className="mx-auto max-w-xl text-center lg:max-w-2xl"
        >
          <span>{header || 'Find out more'}</span>
        </UI.Heading>

        <UI.Accordion contentTag="h2" items={accordionItems} divided={false} />
      </UI.Block>
    </>
  )
}

export default ProductFaqBlock
