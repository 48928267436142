import { useState } from 'react'
import DownArrowIcon from '@/images/icons/arrowDown_icon.svg?svgr'
import * as UI from '@/ui'
import { cn } from '@/helpers/tailwind'

type Analysis = {
  id: string
  name: string
  description: string
}

type TestCardProps = {
  title: string
  description: string
  measurements: Analysis[]
}

const TestCard = ({ title, description, measurements }: TestCardProps) => {
  const [expandedAnalysis, setExpandedAnalysis] = useState<string | null>(null)

  return (
    <div className="w-full max-w-3xl rounded-xl bg-selphGreen-400 py-0.5">
      <div className="space-y-1.5 p-6">
        <UI.Heading size="xxs" color="white">
          {title}
        </UI.Heading>
        <UI.Paragraph color="offWhite" size="small" weight="thin" className="text-selphWhite-500">
          {description}
        </UI.Paragraph>
      </div>

      <div className="mx-6 mb-3 h-[1px] bg-selphWhite-500/40" />

      <div className="space-y-4 p-6 pt-0">
        <p className="text-sm text-selphWhite-500">What&apos;s measured:</p>
        <ul className="list-none space-y-2">
          {measurements.map((analysis) => (
            <li key={analysis.id} className="text-selphWhite-500">
              <button
                onClick={() => setExpandedAnalysis(expandedAnalysis === analysis.id ? null : analysis.id)}
                className="flex w-full items-center gap-2 text-left"
              >
                <span className="flex size-6 items-center justify-center rounded-full bg-selphAmber-500">
                  <DownArrowIcon
                    className={cn(
                      expandedAnalysis === analysis.id ? '-rotate-180' : 'rotate-0',
                      'size-6 transform rounded-full bg-selphAmber-500 p-1 duration-300',
                    )}
                    aria-hidden="true"
                  />
                </span>
                <span>{analysis.name}</span>
              </button>

              {expandedAnalysis === analysis.id && (
                <p className="mt-2 pl-8 text-sm text-selphWhite-500">{analysis.description}</p>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="mx-6 mb-8 h-[1px] bg-selphWhite-500/40" />
    </div>
  )
}

export default TestCard
