import { buildSectionId } from '../content-block/side-navigation'
import { productStaticSectionsIds } from '../../pages/tests/[slug].page'
import RelatedProductsList from './related-products-list'
import { RelatedProductProps } from './related-product-card'
import * as UI from '@/ui'

export type RelatedProductsBlockProps = {
  relatedProducts: RelatedProductProps[]
  title?: string
}

export const RelatedProductsBlock = ({ relatedProducts, title = 'Related Products' }: RelatedProductsBlockProps) => {
  return (
    <UI.Block gap="large" className="mx-auto" id={buildSectionId(productStaticSectionsIds.relatedProducts)}>
      <UI.Badge size="medium" color="outline" className="capitalize">
        Shop our tests
      </UI.Badge>
      <UI.Block gap="small">
        <div className="flex justify-between">
          <UI.Heading as="h2" size={{ default: 'medium', lg: 'large' }}>
            {title}
          </UI.Heading>

          <UI.Link to="tests" type="button" color="outline" className="hidden md:block">
            Shop All
          </UI.Link>
        </div>

        {/* TODO: get RelatedProducts from product */}
        <RelatedProductsList products={relatedProducts} />
      </UI.Block>

      <div className="relative -mt-8 ml-0">
        <UI.Link to="tests" type="button" color="outline" className="md:hidden">
          Shop All
        </UI.Link>
      </div>
    </UI.Block>
  )
}

export default RelatedProductsBlock
