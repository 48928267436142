import { ParsedUrlQuery } from 'querystring'
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next'
import { useEffect, useMemo } from 'react'
import ReactGa from 'react-ga4'
import { isEmpty } from 'lodash-es'
import { NextSeo } from 'next-seo'
import { gql } from '@apollo/client'
import { useRouter } from 'next/router'
import ProductDetails from '../../components/product/product-details'
import { ProductFaqBlock } from '../../components/product/product-faq-block'
import { ProductRelatedArticles } from '../../components/product/product-related-articles'
import { ProductTestList } from '../../components/product/product-test-list'
import { ContentBlock } from '../../components/content-block/content-block'
import StickyBuyButton from '../../components/product/product-buy-button-sticky'
import { client } from '../../services/apollo-client'
import { ImageProps } from '../../components/ui'
import ProductReviews from '../../components/product/product-reviews'
import { useProductDetails } from '../../components/product/use-product-details'
import { getParam } from '../../helpers/params'
import { useMailchimp } from '../../components/user/use-mailchimp'
import { useEngagement } from '../../components/user/use-engagement'
import { useBasket } from '../../components/checkout/use-basket'
import StillHaveAQuestionBlock from '../../components/ui/still-have-a-question-block'
import RelatedProductsBlock from '../../components/product/related-products-block'
import StatsBlock from '../../components/ui/stats-block'
import PrivacyPromise from '../../components/ui/privacy-promise'
import {
  AllTestProductsQuery,
  AllTestProductsQueryVariables,
  BlogCardFragmentDoc,
  BloodCollectionOption,
  BloodCollectionOptionsFragmentDoc,
  BloodCollectionOptionsQuery,
  LabTestProduct,
  LineFragment,
  ProductType,
  RecommendedAddonsFragmentDoc,
  SampleType,
  TestInfoFragmentDoc,
  TestProductBySlugQuery,
  TestProductBySlugQueryVariables,
  TestProductFragmentDoc,
} from '@/gql'
import * as UI from '@/ui'

export type SlugTestProductProps = {
  product: InferGetStaticPropsType<typeof getStaticProps>['product']
  bco: InferGetStaticPropsType<typeof getStaticProps>['bco']
}

type ProductParams = {
  slug: string
}

export type ProductProps = {
  product: Awaited<ReturnType<typeof getProductBySlug>>
  bco: Awaited<ReturnType<typeof getBco>>
}

export interface JsonLdProductData {
  '@context': 'https://schema.org'
  '@type': 'Product'
  name: string
  sku: string
  description: string
  url: string
  brand: {
    '@type': 'Brand'
    name: 'Selph'
  }
  image: string
  offers: {
    '@type': 'Offer'
    price: number
    priceCurrency: string
    availability: string
    shippingDetails?: {
      '@type': 'OfferShippingDetails'
      shippingRate: {
        '@type': 'MonetaryAmount'
        value: number
        currency: string
      }
      shippingDestination: [
        {
          '@type': 'DefinedRegion'
          addressCountry: string
        },
      ]
    }
  }
}

export const TestProductPage = ({ product, bco }: SlugTestProductProps) => {
  const { query: params, isReady: isRouterReady } = useRouter()
  const { updateUser } = useMailchimp()
  const { recentEmail } = useEngagement()
  const { isEmpty: isBasketEmpty, isReady: isBasketReady } = useBasket()
  const modifiersFromParams = useMemo(() => {
    if (isRouterReady && params.modifiers) {
      return paramsFromUrl(params)
    } else return []
  }, [params, isRouterReady])

  const recommendedAddons = useMemo(() => {
    if (!product) return []

    const fullAddonData = product.recommendedAddons.map((addon) => {
      return {
        ...addon,
        testAddon: {
          ...addon.testAddon,
          capillary: addon.testAddon.test.capillary,
          sample: addon.testAddon.test.sample,
        },
      }
    })

    return fullAddonData
  }, [product])

  const availableBco = useMemo(() => {
    return getAvailableBco({ product, bco })
  }, [product, bco])

  const productDetails = useProductDetails({
    productPrice: product?.price || 0,
    recommendedAddons,
    availableBco,
    bco,
    modifiers: modifiersFromParams,
  })

  const { modifiers, price, disableBuyButton, getInitialState } = productDetails

  const mcProductData = {
    LASTPROD: product?.name,
    PRODLINK: `https://www.selph.co.uk/tests/${product?.slug}`,
    PRODPRICE: product && product?.price / 100,
  }

  // track product view with GA4
  useEffect(() => {
    product &&
      ReactGa.event('view_item', {
        currency: 'GBP',
        value: product.price / 100,
        items: [{ item_id: product.sku, id: product.sku, item_name: product.name, price: product.price / 100 }],
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // add browsing tag to MC is we have email and basket is empty
  useEffect(() => {
    if (!recentEmail || !isBasketReady || !isBasketEmpty()) return
    const timer = setTimeout(() => {
      updateUser({ email: recentEmail, tags: ['browsing'], vars: { ...mcProductData } })
    }, 15000)
    // clear timer if the component unmounts
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBasketReady, recentEmail])

  // add browsing tag to MC if user signs up to mailing list and basket is empty
  useEffect(() => {
    const onSignUp = (e: any) => {
      if (isBasketEmpty()) {
        updateUser({ email: e.detail, tags: ['browsing'], vars: { ...mcProductData } })
      }
    }
    window.addEventListener('newsletterSignup', onSignUp)

    return () => {
      window.removeEventListener('newsletterSignup', onSignUp)
    }
  }, [])

  useEffect(() => {
    if (!isRouterReady) return

    if (params.modifiers) {
      const initialState = productDetails.getInitialState({
        bco,
        availableBco,
        recommendedAddons: productDetails.state.availableAddons,
        modifiers: modifiersFromParams,
      })

      productDetails.resetState(initialState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRouterReady, params.modifiers])

  if (!product) throw new Error('No product supplied')

  const jsonLdData = () => {
    const data: JsonLdProductData = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: product.name,
      sku: product.sku,
      description: product.description,
      url: `/tests/${product.slug}`,
      brand: {
        '@type': 'Brand',
        name: 'Selph',
      },
      image: product.shoppingFeedImage?.src
        ? `/images/product/${product.shoppingFeedImage.src}`
        : `/images/product/${product.featuredImage.src}`,
      offers: {
        '@type': 'Offer',
        price: product.price / 100,
        priceCurrency: 'GBP',
        availability: product.allowPurchase ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
        shippingDetails: {
          '@type': 'OfferShippingDetails',
          shippingRate: {
            '@type': 'MonetaryAmount',
            value: 0,
            currency: 'GBP',
          },
          shippingDestination: [
            {
              '@type': 'DefinedRegion',
              addressCountry: 'GB',
            },
          ],
        },
      },
    }

    return data
  }

  const staticSections = [
    ...(product?.testGroups ? [productStaticSectionsIds.whatsTested] : []),
    ...(product?.faqItems ? [productStaticSectionsIds.faqs] : []),
    ...(product?.relatedProducts ? [productStaticSectionsIds.relatedProducts] : []),
    ...(product?.blogArticles ? [productStaticSectionsIds.relatedArticles] : []),
  ]

  const initialState = getInitialState({
    bco,
    availableBco,
    recommendedAddons,
    modifiers: [],
  })

  return (
    <>
      <script
        id="labtestJsonLd"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData()) }}
      />

      <NextSeo
        title={product.metaTitle || product.name}
        description={product.metaDescription || product.description}
        noindex={!product.showInListings || !product.allowPurchase}
        canonical={`https://www.selph.co.uk/tests/${product.slug}`}
      />

      {product.allowPurchase && (
        <StickyBuyButton product={product} selectedModifiers={modifiers} price={price} disabled={disableBuyButton} />
      )}

      <UI.Block gap="xxl" className="mx-auto w-full max-w-7xl px-4 pb-16 md:px-6">
        <ProductDetails product={product} productDetails={productDetails} initialState={initialState} />
      </UI.Block>

      {/* "Product Reviews" section */}
      {!isEmpty(product.reviews) && (
        <div className="w-full bg-selphGreen-500">
          <div className="mx-auto max-w-7xl">
            <ProductReviews reviews={product.reviews} />
          </div>
        </div>
      )}

      {/* "What you need to know..." section */}
      {product.productInfo && (
        <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6">
          <ContentBlock staticSections={staticSections} content={product.productInfo} />
        </div>
      )}

      {/* "What's tested..." section */}
      <div className="w-full bg-selphGreen-500">
        <div className="mx-auto max-w-7xl px-4 sm:px-6">
          <ProductTestList
            header={product.whatsTestedHeader}
            productName={product.name}
            included={product.testGroups.map((group) => ({
              name: group.name,
              tests: group.tests,
              hideAnalyses: group.hideAnalyses,
              analyses: group.analyses.map(({ id }) => id),
              why: group.why,
            }))}
          >
            {product.whatsTestedCopy}
          </ProductTestList>
        </div>
      </div>

      <UI.Block gap="xxl" className="mx-auto max-w-7xl px-4 sm:px-6">
        {/* "FAQs" section */}
        {product.faqItems.length > 0 && <ProductFaqBlock header={product.faqHeader} items={product.faqItems} />}

        {/* "Still have a question?" section */}
        <StillHaveAQuestionBlock showBadge>
          <UI.Paragraph size={{ default: 'medium', lg: 'large' }} className="mx-auto max-w-4xl px-6 sm:px-10 md:px-14">
            If you have any questions, we&apos;re here to help. Our customer service team is hands-down the best
            you&apos;ll ever deal with. They&apos;re smart, friendly, knowledgeable and will get back to you in a flash.
          </UI.Paragraph>
        </StillHaveAQuestionBlock>

        {/* "Related Products" section */}
        {product.relatedProducts && <RelatedProductsBlock relatedProducts={product.relatedProducts} />}

        {/* "Related Articles" section */}
        {product.blogArticles && (
          <div className="mx-auto">
            <ProductRelatedArticles blogs={product.blogArticles} />
          </div>
        )}

        {/* "Statistics Blocks" section */}
        <div className="my-20">
          <StatsBlock />
        </div>

        {/* "Privacy Promise" section */}
        <div className="mx-auto">
          <PrivacyPromise />
        </div>
      </UI.Block>
    </>
  )
}

export const productStaticSectionsIds = Object.freeze({
  whatsTested: 'What does this test measure?',
  faqs: 'FAQs',
  relatedProducts: 'Related Products',
  relatedArticles: 'Related Articles',
})

const productsWithStaticPages = ['corporate-qfit-test', 'betterhealth']

export const getStaticPaths: GetStaticPaths<ProductParams> = async () => {
  const { data, error } = await client.query<AllTestProductsQuery, AllTestProductsQueryVariables>({
    query: allTestProducts,
    variables: { filter: { types: [ProductType.LabTest], includeHidden: true } },
  })

  if (error) {
    console.error('TestProduct: ', error)
  }

  const paths = data.products.filter((product) => !productsWithStaticPages.includes(product.slug))

  return {
    paths: paths.map((product) => ({
      params: {
        slug: product.slug,
      },
    })),
    fallback: false,
  }
}

export const getStaticProps: GetStaticProps<ProductProps> = async ({ params }) => {
  const slug = params?.slug as string

  const product = await getProductBySlug(slug)
  const bco = await getBco()

  return {
    props: {
      product,
      bco,
    },
  }
}

export default TestProductPage

TestProductPage.layoutProps = {
  containerStyle: 'verticalContained',
}

async function getProductBySlug(slug: string) {
  const { getPlaiceholder } = await import('plaiceholder')
  const { extractImgSrc } = await import('@plaiceholder/tailwindcss/utils')

  const response = await client.query<TestProductBySlugQuery, TestProductBySlugQueryVariables>({
    query: testProductBySlug,
    variables: { slug },
  })

  const product = response.data?.productBySlug?.__typename === 'LabTestProduct' ? response.data.productBySlug : null

  if (!product) return null

  // placeholder for videos
  const videos = null

  const images = [
    product?.featuredImage && {
      name: product.featuredImage.name,
      src: `/images/product/${product.featuredImage.src}`,
      alt: product.featuredImage.name,
    },
    ...(product?.otherImages || []).map((image) => ({
      name: image.name,
      src: `/images/product/${image.src}`,
      alt: image.name,
    })),
  ]

  const imageProps = await Promise.all(
    images.map(async (image): Promise<ImageProps> => {
      const placeholder = await getPlaiceholder(extractImgSrc(image.src))
      return {
        blurImage: placeholder.base64,
        ...(placeholder.img as Omit<ImageProps, 'placeholder'>),
      } as ImageProps
    }) as Promise<ImageProps>[],
  )

  return {
    ...product,
    images,
    imageProps,
    videos,
  }
}

export async function getBco() {
  const { data } = await client.query<BloodCollectionOptionsQuery>({
    query: bloodCollectionOptions,
  })

  return data.bloodCollectionOptions
}

export const getPrice = (
  selectedModifiers: Set<string>,
  bco: SlugTestProductProps['bco'],
  product: SlugTestProductProps['product'],
) => {
  if (!product) {
    console.error('No data available to calculate the price')
  }

  return [...selectedModifiers].reduce((acc, curr) => {
    const bcoData = bco.find((option) => option.code === curr)
    const addon = product?.recommendedAddons?.find((addon) => addon.testAddon.code === curr)

    return acc + (bcoData?.price ?? 0) + (addon?.testAddon.price ?? 0)
  }, product?.price || 0)
}

export const paramsFromUrl = (params: ParsedUrlQuery) => {
  const selectedModifiers = new Set<string>()

  getParam(params.modifiers)
    .toString()
    .split(',')
    .forEach((modifier) => {
      selectedModifiers.add(modifier)
    })

  return [...selectedModifiers]
}

export function getAvailableBco({
  product,
  bco,
}: {
  product: SlugTestProductProps['product'] | LineFragment['product']
  bco: SlugTestProductProps['bco']
}): BloodCollectionOption[] {
  if (!product) return []

  if (isLabTestProduct(product)) {
    const hasBloodTest = (product.testGroups || []).filter((group) =>
      group.tests.some((test) => test.sample === SampleType.Blood),
    )

    if (!hasBloodTest || isEmpty(hasBloodTest)) {
      return []
    }

    const canDoCapillary = hasBloodTest.every((group) =>
      group.tests.filter((test) => test.sample === SampleType.Blood).every((test) => test.capillary),
    )

    if (canDoCapillary) {
      return bco
    }

    return bco.filter((option) => option.code !== 'BCO-CAP')
  } else return []
}

export function isLabTestProduct(product: any): product is LabTestProduct {
  return product.type === ProductType.LabTest || product.type === ProductType.BespokeLabTest
}

export const testProductBySlug = gql`
  query testProductBySlug($slug: ID!) {
    productBySlug(slug: $slug) {
      __typename
      ... on LabTestProduct {
        ...TestProduct
      }
    }
  }

  ${TestProductFragmentDoc}
`

export const allTestProducts = gql`
  query allTestProducts($filter: ProductListFilter) {
    products(filter: $filter) {
      slug
    }
  }
`

gql`
  fragment TestProduct on LabTestProduct {
    __typename
    sku
    name
    type
    slug
    price
    allowPurchase
    showInListings
    description
    intro
    metaDescription
    metaTitle
    features
    spokesperson {
      title
      firstName
      lastName
      role
      headImage {
        src
        name
      }
      cutoutImage {
        src
        name
      }
    }
    featuredImage {
      src
      name
    }
    otherImages {
      src
      name
    }
    shoppingFeedImage {
      src
    }
    categories
    productInfo {
      heading
      copy
    }
    whatsTestedHeader
    personaliseHeader
    blogsHeader
    blogArticles {
      ...BlogCard
    }
    relatedProducts {
      __typename
      sku
      name
      type
      slug
      description
      featuredImage {
        src
        name
      }
    }
    faqHeader
    faqItems {
      question
      answer
    }
    whatsTestedCopy
    testGroups {
      hideAnalyses
      analyses {
        id
      }
      name
      tests {
        ...TestInfo
      }
      why
    }
    biomarkers
    sampleLocation
    analysisLocation
    turnaroundTime
    sampleTypes
    whoFor
    reviews {
      date
      content
      link
      author
    }
    ...BloodCollectionOptions
    ...RecommendedAddons
  }

  ${BlogCardFragmentDoc}
  ${TestInfoFragmentDoc}
  ${BloodCollectionOptionsFragmentDoc}
  ${RecommendedAddonsFragmentDoc}
`

gql`
  fragment TestInfo on TestInfo {
    __typename
    id
    name
    description
    capillary
    categories
    sample
    analyses {
      id
      name
      description
    }
    turnaroundTime
  }
`

export const bloodCollectionOptions = gql`
  query BloodCollectionOptions {
    bloodCollectionOptions {
      id
      code
      name
      description
      price
      bloodDrawType
    }
  }
`

gql`
  fragment BloodCollectionOptions on LabTestProduct {
    __typename
    bloodCollectionOptions {
      id
      code
      name
      description
      price
      bloodDrawType
    }
  }
`

gql`
  fragment RecommendedAddons on LabTestProduct {
    __typename
    recommendedAddons {
      testAddon {
        code
        price
        standalonePrice
        name
        test {
          friendlyName
          capillary
          sample
        }
      }
      why
    }
  }
`

gql`
  fragment BlogCard on Article {
    __typename
    title
    path
    slug
    publishedAt
    updatedAt
    readingTimeMinutes
    categories
    hero {
      src
    }
  }
`
