import * as UI from '@/ui'

export const StillHaveAQuestionBlock = ({
  showBadge = false,
  children,
}: {
  showBadge?: boolean
  children?: React.ReactNode
}) => {
  return (
    <UI.Block className="mx-auto text-center">
      {showBadge && (
        <UI.Badge size="medium" color="outline" className="capitalize">
          Get in touch
        </UI.Badge>
      )}

      <UI.Heading size={{ default: 'medium', lg: 'large' }} className="mx-auto max-w-xl lg:max-w-2xl">
        Still have questions?
      </UI.Heading>

      {children}

      <UI.Link to="contact" type="button">
        Contact Us
      </UI.Link>
    </UI.Block>
  )
}

export default StillHaveAQuestionBlock
