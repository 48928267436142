import React, { useMemo } from 'react'
import * as UI from '@/ui'

interface NavigationItem {
  label: string
  href?: string
  isStatic?: boolean
}

interface SideNavigationProps {
  contentSections: string[]
  staticSections?: string[]
  video?: {
    title: string
    description: string
    src: string
  }
}

export const SideNavigation = ({ contentSections, staticSections = [], video }: SideNavigationProps) => {
  const navigationItems = useMemo((): NavigationItem[] => {
    const contentItems = contentSections.map(
      (header): NavigationItem => ({
        label: header,
        href: buildSectionId(header),
      }),
    )

    const staticItems: NavigationItem[] = staticSections.map((label) => ({
      label,
      isStatic: true,
      href: buildSectionId(label),
    }))

    return [...contentItems, ...staticItems]
  }, [contentSections, staticSections])

  if (navigationItems.length === 0) {
    return null
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    e.preventDefault()
    const element = document.getElementById(href)

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  return (
    <UI.Block display="flexCol" className="gap-y-40">
      <nav className="rounded-lg bg-selphGreen-500 px-3 py-6 text-selphWhite-500">
        {navigationItems.map((item, index) => (
          <a
            key={index}
            href={item.href}
            onClick={(e) => handleClick(e, item.href || '')}
            className="mb-2 block rounded-lg px-4 py-1 text-lg leading-snug transition-all duration-300 last:mb-0 hover:bg-selphAmber-500"
          >
            {item.label}
          </a>
        ))}
      </nav>

      {video && (
        <div className="rounded-lg">
          <iframe
            src={video.src}
            title={video.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className="aspect-video h-auto w-full rounded-lg"
          />
          <UI.Paragraph color="orange" size="small" weight="regular" className="pl-2">
            {video.title}
          </UI.Paragraph>
          <UI.Paragraph color="black" size="small" weight="regular" className="pl-2">
            {video.description}
          </UI.Paragraph>
        </div>
      )}
    </UI.Block>
  )
}

export default SideNavigation

export const buildSectionId = (label: string): string => {
  return `#section-${label.toLowerCase().replace(/\s+/g, '-')}`
}
