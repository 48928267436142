import ArticleFeed from '../ui/carousel/article-feed'
import { buildSectionId } from '../content-block/side-navigation'
import { productStaticSectionsIds } from '../../pages/tests/[slug].page'
import blurImageJson from '../../data/markdown-blur-images.json'
import { Article, BlogCardFragment } from '@/gql'
import * as UI from '@/ui'

export const ProductRelatedArticles = ({
  blogs,
  title,
  linkSection,
  linkAll = true,
  showBadge = true,
}: {
  blogs: BlogCardFragment[] | Article[]
  title?: string
  linkSection?: string
  linkAll?: boolean
  showBadge?: boolean
}) => {
  return (
    <UI.Block className="mx-auto max-w-7xl" id={buildSectionId(productStaticSectionsIds.relatedArticles)}>
      {showBadge && (
        <UI.Badge size="medium" color="outline" className="capitalize">
          Selph Insights
        </UI.Badge>
      )}
      <UI.Block gap="small">
        <div className="flex justify-between">
          <UI.Heading as="h2" color="black" size={{ default: 'medium', lg: 'large' }}>
            {title ? title : 'Related Articles'}
          </UI.Heading>

          {linkAll && (
            <UI.Link
              to={linkSection ? ['learnCategory', { slug: linkSection }] : 'learn'}
              type="button"
              color="outline"
              className="hidden md:block"
            >
              View All
            </UI.Link>
          )}
        </div>

        <div className="mx-auto lg:hidden">
          <ArticleFeed blogs={blogs} />
        </div>
      </UI.Block>

      <div className="hidden lg:block">
        <div className="mx-auto grid h-[450px] grid-cols-4 grid-rows-2 gap-6">
          <div className="group col-span-2 row-span-2 overflow-hidden rounded-xl transition-all duration-300 hover:shadow-xl">
            <UI.Link color="none" to={['blogPost', { path: blogs[0].path }]}>
              <div className="relative h-2/3 overflow-hidden rounded-t-xl">
                <UI.Image
                  className="rounded-t-xl object-cover object-center transition-transform duration-300 group-hover:scale-110"
                  blurImageJson={blurImageJson}
                  blurImageJsonFolder="/images/blogs/"
                  fill
                  src={`/images${blogs[0].hero.src}`}
                  alt={blogs[0].title}
                />
              </div>
              <div className="flex h-1/3 flex-col gap-y-4 bg-selphGreen-500 p-6 group-hover:bg-selphGreen-600">
                <span className="text-xl leading-tight font-light text-selphWhite-500">{blogs[0].title}</span>
                <div className="flex items-center justify-between">
                  <span className="rounded-md bg-selphGreen-400 px-3 py-2 font-light text-selphWhite-500">
                    {blogs[0].categories[0]}
                  </span>
                  <span className="font-light text-selphWhite-500">{blogs[0].readingTimeMinutes} min read</span>
                </div>
              </div>
            </UI.Link>
          </div>

          <div className="group col-span-2 row-span-1 w-full overflow-hidden rounded-xl transition-all duration-300 hover:shadow-xl">
            <UI.Link color="none" to={['blogPost', { path: blogs[1].path }]} className="flex h-full">
              <div className="relative aspect-square shrink-0 overflow-hidden rounded-l-xl">
                <UI.Image
                  className="rounded-l-xl object-cover object-center transition-transform duration-300 group-hover:scale-110"
                  blurImageJson={blurImageJson}
                  blurImageJsonFolder="/images/blogs/"
                  fill
                  src={`/images${blogs[1].hero.src}`}
                  alt={blogs[1].title}
                />
              </div>
              <div className="flex w-full flex-col justify-between bg-selphOrange-500 p-6 group-hover:bg-selphOrange-600">
                <span className="text-xl leading-tight font-light text-selphWhite-500">{blogs[1].title}</span>
                <div className="flex items-center justify-between">
                  <span className="rounded-md bg-selphOrange-400 px-3 py-2 font-light text-selphWhite-500">
                    {blogs[1].categories[0]}
                  </span>
                  <span className="font-light text-selphWhite-500">{blogs[1].readingTimeMinutes} min read</span>
                </div>
              </div>
            </UI.Link>
          </div>

          <div className="group col-span-2 row-span-1 w-full overflow-hidden rounded-xl transition-all duration-300 hover:shadow-xl">
            <UI.Link color="none" to={['blogPost', { path: blogs[2].path }]} className="flex h-full">
              <div className="relative aspect-square shrink-0 overflow-hidden rounded-l-xl">
                <UI.Image
                  className="rounded-l-xl object-cover object-center transition-transform duration-300 group-hover:scale-110"
                  blurImageJson={blurImageJson}
                  blurImageJsonFolder="/images/blogs/"
                  fill
                  src={`/images${blogs[2].hero.src}`}
                  alt={blogs[2].title}
                />
              </div>
              <div className="flex w-full flex-col justify-between bg-selphTeal-500 p-6 group-hover:bg-selphTeal-600">
                <span className="text-xl leading-tight font-light text-selphWhite-500">{blogs[2].title}</span>
                <div className="flex items-center justify-between">
                  <span className="rounded-md bg-selphTeal-400 px-3 py-2 font-light text-selphWhite-500">
                    {blogs[2].categories[0]}
                  </span>
                  <span className="font-light text-selphWhite-500">{blogs[2].readingTimeMinutes} min read</span>
                </div>
              </div>
            </UI.Link>
          </div>
        </div>
      </div>

      {linkAll && (
        <UI.Link to="learn" type="button" color="outline" className="md:hidden">
          View All
        </UI.Link>
      )}
    </UI.Block>
  )
}

export default ProductRelatedArticles
