import { ProductDetailsFromState } from './use-product-details'
import ProductAddOnsModalItem from './product-addons-modal-item'
import * as UI from '@/ui'

type ProductAddonsModalProps = {
  productDetails: ProductDetailsFromState
}

const ProductAddonsModal = ({ productDetails }: ProductAddonsModalProps) => {
  const { state, closeModal, confirmAddOns } = productDetails

  const isClinicSelectionRequired = state.selectedBco?.code === 'BCO-IVD-CLINIC' && !state.selectedClinicId

  const handleConfirm = () => {
    confirmAddOns()
  }

  return (
    <UI.Modal color="light" show={!!productDetails} onClose={closeModal}>
      <UI.Block gap="small" className="max-w-lg">
        <UI.Block gap="xs">
          <UI.Heading as="p" size={{ default: 'xs', lg: 'small' }}>
            Add to your test
          </UI.Heading>
          <UI.Paragraph size={{ default: 'medium', lg: 'large' }} className="leading-tight">
            Depending on your age, sex and any symptoms you may want to add to your test and save.
          </UI.Paragraph>
        </UI.Block>
        <UI.Paragraph size={{ default: 'medium', lg: 'large' }}>{state.modal?.props.addonToAdd}</UI.Paragraph>

        {state.availableAddons.map((addOn) => (
          <ProductAddOnsModalItem key={addOn.testAddon.code} addOn={addOn} productDetails={productDetails} />
        ))}

        <UI.Button
          size="full"
          onClick={handleConfirm}
          disabled={isClinicSelectionRequired}
          errorMessage={isClinicSelectionRequired ? 'Please select a clinic.' : ''}
          className="mx-auto mt-6"
        >
          Make Selection To Proceed
        </UI.Button>
      </UI.Block>
    </UI.Modal>
  )
}

export default ProductAddonsModal
