import { useEffect, useState } from 'react'
import { ProductProps } from '../../pages/tests/[slug].page'
import TrustpilotBox from '../Trustpilot/trustpilot-box'
import MoneyBackGuarantee from '../ui/money-back-guarantee'
import { ClinicDataResponse } from '../../pages/api/clinic-info.api'
import { fetchClinicData } from '../account/kit-activation/clinic-selector'
import ProductBuyButton, { BuyButtonId } from './product-buy-button'
import { ProductDetailsFromState, ProductDetailsState } from './use-product-details'
import ProductBcoConfirmationModal from './product-bco-confirmation-modal'
import ProductAddonsModal from './product-addons-modal'
import Tick_Icon from '@/images/icons/tick_icon.svg?svgr'
import Plus_Icon from '@/images/icons/plus_icon.svg?svgr'
import { ProductType, TestProductFragment } from '@/gql'
import Klarna_Icon from '@/images/payment/klarna_logo_badge.svg?svgr'
import Clearpay_Icon from '@/images/payment/clearpay_logo.svg?svgr'

import { cn } from '@/helpers/tailwind'
import * as UI from '@/ui'

type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[] ? ElementType : never

type ProductDetailsProps = {
  product: ProductProps['product']
  productDetails: ProductDetailsFromState
  initialState: ProductDetailsState
}

export const ProductDetails = ({ product, productDetails, initialState }: ProductDetailsProps) => {
  const [clinic, setClinic] = useState<ClinicDataResponse | null>(null)

  const { state, modifiers, price, showBcoModal, showAddOnsModal, disableBuyButton, resetState } = productDetails

  useEffect(() => {
    const clinicModifier = [...modifiers].find((modifier) => modifier.startsWith('BCO-IVD-CLINIC'))

    if (!clinicModifier) {
      setClinic(null)
      return
    }

    const clinicId = clinicModifier.replace('BCO-IVD-CLINIC-', '')

    ;(async () => {
      const response = await fetchClinicData({ id: clinicId })
      setClinic(response || null)
    })()
  }, [modifiers])

  if (!product) return null

  const bcoName = (modifierName: string) => {
    return clinic?.name ? `Blood draw at ${clinic.name}` : modifierName
  }

  const handleBcoChange = () => {
    if (!state || !showBcoModal) return

    const addonToAdd = state.selectedBco?.code
    const availableBco = state.availableBco

    if (!addonToAdd) return

    showBcoModal(addonToAdd, availableBco)
  }

  const handleAddonChange = () => {
    if (!state || !showAddOnsModal) return
    showAddOnsModal()
  }

  const highestSaving = product.recommendedAddons.reduce((max, addOn) => {
    const saving = addOn.testAddon.standalonePrice
      ? Math.round((1 - addOn.testAddon.price / addOn.testAddon.standalonePrice) * 100)
      : undefined
    return Math.max(max, saving || 0)
  }, 0)

  const selectedAddonsSize = getSelectedAddonsSize(state.selectedAddons)

  return (
    <div className="relative z-10 flex flex-col gap-x-10 gap-y-10 md:flex-row-reverse" id="product-details-block">
      <div className="flex-1">
        <UI.Block>
          <UI.Heading as="h1" size={{ default: 'medium', lg: 'large' }}>
            {product.name}
          </UI.Heading>

          <UI.Block gap="medium" id="productDescription">
            {product.intro ? (
              <UI.Markdown>{product.intro}</UI.Markdown>
            ) : (
              <>
                <UI.Markdown>{product.description}</UI.Markdown>
              </>
            )}

            <div className="flex flex-wrap items-baseline gap-x-2 text-left text-xl">
              <UI.Paragraph>Comes with our </UI.Paragraph>{' '}
              <MoneyBackGuarantee
                className="font-normal transition-all duration-300 hover:text-selphAmber-500"
                panelClassName="w-[90vw] md:w-xl  max-md:left-0 md:right-0"
              >
                Money Back Guarantee.
              </MoneyBackGuarantee>
            </div>

            <UI.List listStyle="none" id="product-features" margin="none" gap="large">
              {['LT-QFBCT', 'LT-PSA'].includes(product.sku) ? (
                <UI.ListItem>
                  <UI.Badge type="round" color="outline" className="font-normal">
                    Same day results (for over 90% of tests)
                  </UI.Badge>
                </UI.ListItem>
              ) : (
                <UI.ListItem>
                  <UI.Badge type="round" color="outline" className="font-normal">
                    Results and doctor&apos;s report in {product.turnaroundTime + 1} day
                    {product.turnaroundTime && product.turnaroundTime + 1 > 1 ? 's' : ''} or less
                  </UI.Badge>
                </UI.ListItem>
              )}

              <UI.ListItem>
                <UI.Badge type="round" color="outline" className="font-normal">
                  Free, next-working day delivery
                </UI.Badge>
              </UI.ListItem>

              {product.features &&
                product.features.map((feature, i) => (
                  <UI.ListItem key={i}>
                    <UI.Badge type="round" color="outline" className="font-normal">
                      {feature}
                    </UI.Badge>
                  </UI.ListItem>
                ))}

              {product.sku === 'LT-QFBCT' && (
                <UI.ListItem>
                  <UI.Badge type="round" color="outline" className="font-normal">
                    20% of profits donated to{' '}
                    <UI.Link to="https://www.stmarkshospitalfoundation.org.uk/">
                      St Mark&apos;s Hospital Foundation
                    </UI.Link>
                  </UI.Badge>
                </UI.ListItem>
              )}
            </UI.List>

            <div className="sm:hidden">
              <TrustpilotBox boxStyle="withoutLabel" />
            </div>
          </UI.Block>

          {product.allowPurchase ? (
            <UI.Block display="flexCol" gap="medium" className="rounded-xl bg-selphWhite-100 p-2 sm:p-6">
              <UI.Block gap="small">
                <div className="flex items-center justify-between gap-x-2">
                  <UI.Heading as="p" color="black" size={{ default: 'xxs', sm: 'xs' }} className="leading-tight">
                    Personalise your test
                  </UI.Heading>

                  <UI.Button type="text" onClick={() => resetState(initialState)} className="text-right leading-tight">
                    Reset selections
                  </UI.Button>
                </div>

                <div className="flex items-center justify-between gap-x-2">
                  <UI.Paragraph size={{ default: 'small', sm: 'medium' }} className="leading-tight">
                    {product.name} base price:
                  </UI.Paragraph>
                  <span className="text-base font-normal sm:text-lg">
                    <UI.Currency inputUnit="pence" value={product.price}></UI.Currency>
                  </span>
                </div>

                <UI.Divider />
              </UI.Block>

              <UI.Block>
                {state.selectedBco && (
                  <UI.Block gap="none" className="rounded-lg bg-selphWhite-300 px-2 py-2 sm:px-6">
                    <div className="flex items-center justify-between gap-x-2">
                      <div className="flex items-center gap-x-2">
                        <span className="flex size-4 shrink-0 items-center justify-center rounded-full bg-selphOrange-500 p-0.5 text-selphWhite-500 ring ring-selphOrange-500">
                          <Tick_Icon className="h-3" />
                        </span>

                        <UI.Paragraph className="leading-none font-normal" size={{ default: 'small', sm: 'medium' }}>
                          {bcoName(state.selectedBco.name)}
                        </UI.Paragraph>
                      </div>

                      {state.selectedBco.price === 0 ? (
                        <span>Free</span>
                      ) : (
                        <UI.Currency inputUnit="pence" value={state.selectedBco.price}></UI.Currency>
                      )}
                    </div>

                    <UI.Divider />

                    <UI.Button type="text" onClick={handleBcoChange} rootClassName="ml-7">
                      View collection options
                    </UI.Button>
                  </UI.Block>
                )}

                {state.availableAddons.length > 0 && (
                  <UI.Block className="rounded-lg bg-selphWhite-300 px-2 py-2 sm:px-6">
                    <UI.Grid size={12} colVerticalAlign="middle" gap="xs">
                      {selectedAddonsSize > 0 && (
                        <UI.GridSpan size={12} align="start" className="w-full">
                          {Array.from(state.selectedAddons).map((addon) => {
                            const addonData = product.recommendedAddons.find((addOn) => addOn.testAddon.code === addon)

                            return (
                              <div key={addon}>
                                {addonData && (
                                  <div className="my-2 flex items-center justify-between gap-x-2">
                                    <div className="flex items-center gap-x-2">
                                      <span className="flex size-4 shrink-0 items-center justify-center rounded-full bg-selphOrange-500 p-0.5 text-selphWhite-300 ring ring-selphOrange-500">
                                        <Tick_Icon className="h-3" />
                                      </span>

                                      <UI.Paragraph
                                        className="leading-none font-normal"
                                        size={{ default: 'small', sm: 'medium' }}
                                      >
                                        {addonData.testAddon.test.friendlyName || addonData.testAddon.name}
                                      </UI.Paragraph>
                                    </div>
                                    <span>
                                      <UI.Currency value={addonData.testAddon.price} />
                                    </span>
                                  </div>
                                )}
                              </div>
                            )
                          })}

                          <UI.Divider />
                        </UI.GridSpan>
                      )}

                      <UI.GridSpan size={9} align="start">
                        <div className={cn(selectedAddonsSize > 0 && '-mt-2', 'group flex items-center gap-x-2')}>
                          {selectedAddonsSize === 0 ? (
                            <UI.Button
                              type="text"
                              className="flex size-4 shrink-0 items-center justify-center rounded-full border-1 border-selphBlack group-hover:border-selphAmber-500 group-hover:bg-selphAmber-500"
                              onClick={handleAddonChange}
                            >
                              <Plus_Icon className="h-3 rounded-full group-hover:text-selphWhite-500" />
                            </UI.Button>
                          ) : (
                            <span className="w-5" />
                          )}

                          <UI.Button
                            type="text"
                            onClick={handleAddonChange}
                            className="group-hover:text-selphAmber-500"
                          >
                            {selectedAddonsSize > 0 ? 'Change' : `Upgrade and Save`}
                          </UI.Button>
                        </div>
                      </UI.GridSpan>

                      {/* show the highest saving percentage on addons */}
                      <UI.GridSpan size={3} align="end" className="mr-2 leading-none font-medium md:mr-0">
                        {selectedAddonsSize === 0 && (
                          <span className="font-normal whitespace-nowrap text-selphAmber-500">{`Up to ${highestSaving}% off`}</span>
                        )}
                      </UI.GridSpan>
                    </UI.Grid>
                  </UI.Block>
                )}

                {state.modal && state.modal.type === 'BcoModal' && state.modal.props.addonToAdd && (
                  <ProductBcoConfirmationModal {...state.modal.props} productDetails={productDetails} />
                )}

                {state.modal && state.modal.type === 'AddOnsModal' && (
                  <ProductAddonsModal productDetails={productDetails} />
                )}
              </UI.Block>

              <UI.Block gap="small">
                <div className="flex w-full items-center justify-center gap-x-4 gap-y-2 max-sm:flex-wrap">
                  <ProductBuyButton
                    id={BuyButtonId.BuyButton_1}
                    size="full"
                    product={{
                      sku: product.sku,
                      name: product.name,
                      price: product.price,
                      type: product.type,
                    }}
                    selectedModifiers={modifiers}
                    disabled={disableBuyButton}
                    className="w-full text-center"
                  >
                    {product.type === ProductType.LabTest && (
                      <>
                        <span className="text-sm sm:whitespace-nowrap">Includes Money Back Guarantee</span>
                        {product.sku === 'LT-QFBCT' && (
                          <span className="text-sm sm:whitespace-nowrap">& Free Charity Donation</span>
                        )}
                      </>
                    )}
                  </ProductBuyButton>

                  <div className="flex items-baseline gap-x-1 sm:flex-col">
                    <UI.Paragraph size="xs" className="font-light">
                      Total price:
                    </UI.Paragraph>
                    <UI.Paragraph size="xl" className="font-normal tracking-tight">
                      <UI.Currency value={price} id="productPrice" />
                    </UI.Paragraph>
                  </div>
                </div>
                <div id="split-payment-options">
                  <UI.Paragraph size="xs" className="text-center font-light">
                    Split the payment with <Klarna_Icon className="inline h-5" /> or{' '}
                    <Clearpay_Icon className="inline h-5" />
                  </UI.Paragraph>
                </div>
              </UI.Block>
            </UI.Block>
          ) : (
            <UI.Message color="warning" title="Product unavailable" canDismiss={false}>
              <UI.Paragraph>Sorry, this product is currently unavailable.</UI.Paragraph>
            </UI.Message>
          )}
        </UI.Block>
      </div>

      <UI.Gallery
        images={product.images as UI.GalleryImages}
        imageProps={product.imageProps}
        videos={product.videos}
        className="flex-1"
      />
    </div>
  )
}

export default ProductDetails

export const getSampleTypeInfo = (sampleTypes: TestProductFragment['sampleTypes']) => {
  if (
    sampleTypes?.includes('BLOOD' as ArrElement<TestProductFragment['sampleTypes']>) &&
    sampleTypes?.includes('STOOL' as ArrElement<TestProductFragment['sampleTypes']>)
  ) {
    return {
      sample: 'bloodStool',
      sampleText: 'Blood & Stool Samples',
      sampleIcon: 'bloodAndStoolTest_icon.svg',
    }
  } else if (sampleTypes?.includes('BLOOD' as ArrElement<TestProductFragment['sampleTypes']>)) {
    return {
      sample: 'blood',
      sampleText: 'Blood Sample',
      sampleIcon: 'bloodTest_icon.svg',
    }
  } else return { sample: 'stool', sampleText: 'Stool Sample', sampleIcon: 'stoolTest_icon.svg' }
}

/**
 * Returns the size of the selected addons, excluding any that start with "BCO"
 * @param selectedAddons - The set of selected addons
 * @returns The size of the filtered addons
 */
const getSelectedAddonsSize = (selectedAddons: Set<string>) => {
  const filteredAddons = Array.from(selectedAddons).filter((addon) => !addon.startsWith('BCO'))

  return filteredAddons.length
}
