import MiniCarousel from '../ui/mini-carousel/mini-carousel'
import RelatedProductCard, { RelatedProductProps } from './related-product-card'

export type RelatedProductsListProps = {
  products: RelatedProductProps[]
}

export const RelatedProductsList = ({ products }: RelatedProductsListProps) => {
  return (
    <MiniCarousel
      slides={products.map((product) => (
        <RelatedProductCard key={product.sku} product={product} />
      ))}
      id="relatedProducts"
    />
  )
}

export default RelatedProductsList
