import { cn } from '../../helpers/tailwind'
import { buildSectionId, SideNavigation } from './side-navigation'
import OpenQuoIcon from '@/images/icons/openQuo_icon.svg?svgr'
import * as UI from '@/ui'

export type Content = {
  heading: string
  copy: string | React.ReactNode
  quote?: string | null
}[]

export type VideoProps =
  | {
      title: string
      description: string
      src: string
    }
  | undefined

type ContentBlockProps = {
  content: Content
  staticSections?: string[]
  video?: VideoProps
}

export const ContentBlock = ({ content, staticSections, video }: ContentBlockProps) => {
  const contentSections = content.map((section) => section.heading)

  const renderSectionContent = (copy: string | React.ReactNode) => {
    if (typeof copy === 'string') {
      return (
        <UI.Markdown
          components={{
            p: ({ children }: { children: React.ReactNode }) => (
              <UI.Paragraph size={{ default: 'medium', lg: 'large' }} className="mt-4">
                {children}
              </UI.Paragraph>
            ),
            ul: ({ children }: { children: React.ReactNode }) => <UI.List margin="medium">{children}</UI.List>,
            li: ({ children }: { children: React.ReactNode }) => <UI.ListItem>{children}</UI.ListItem>,
          }}
        >
          {copy}
        </UI.Markdown>
      )
    }
    return copy
  }

  const renderContent = () => {
    return content.map((section, index) => (
      <div key={index} className="" id={buildSectionId(section.heading)}>
        <div className={cn(section.quote ? 'mb-10' : '', 'relative')}>
          {section.quote && <OpenQuoIcon width={40} className="absolute -top-4 text-selphGreen-600" />}
          <UI.Heading
            as="h2"
            size={{ default: 'medium', lg: 'large' }}
            weight="thin"
            className={cn(section.quote ? 'pl-10 italic md:pl-20' : '', 'mx-auto mb-5 max-w-5xl min-w-fit')}
          >
            {section.quote || section.heading}
          </UI.Heading>
        </div>

        <div className="md:text-lg">{renderSectionContent(section.copy)}</div>
      </div>
    ))
  }

  return (
    <div
      className="mx-auto flex w-full max-w-7xl flex-col gap-x-10 gap-y-10 md:flex-row lg:gap-x-20"
      id="product-text-block"
    >
      {/* Side Navigation */}
      <div className="w-full shrink-0 md:w-80">
        <div className="sticky top-36">
          <SideNavigation contentSections={contentSections} staticSections={staticSections} video={video} />
        </div>
      </div>

      {/* Main Content */}
      <UI.Block gap="large" className="flex-1">
        {renderContent()}
      </UI.Block>
    </div>
  )
}
