import * as React from 'react'
import { isEmpty } from 'lodash-es'
import { buildSectionId } from '../content-block/side-navigation'
import { productStaticSectionsIds } from '../../pages/tests/[slug].page'
import { Markdown } from '../../components/ui/markdown'
import TestCard from './product-test-card'
import { SampleType } from '@/gql'
import * as UI from '@/ui'

type Tests = {
  id: string
  name: string
  description: string
  categories: string[]
  sample: SampleType
  instructions?: string | null | undefined
  turnaroundTime: number
  analyses: {
    id: string
    name: string
    description: string
  }[]
}[]

export type ProductTestListPropsIncluded = {
  name: string
  why: string
  tests: Tests
  hideAnalyses?: boolean | null
  analyses?: string[]
}

export type ProductTestListProps = {
  productName: string
  included: ProductTestListPropsIncluded[]
  children?: string | null
  header?: string | null
}

export const ProductTestList = ({ included, productName, children, header }: ProductTestListProps) => {
  return (
    <div className="mx-auto flex flex-col items-center py-16" id={buildSectionId(productStaticSectionsIds.whatsTested)}>
      <div className="flex w-full flex-col gap-x-5 gap-y-10 md:flex-row lg:gap-x-20">
        {/* Side Header */}
        <div className="w-full flex-1 md:max-w-md">
          <div className="sticky top-36 max-md:text-center">
            <UI.Heading as="h2" color="white" size={{ default: 'medium', lg: 'large' }}>
              {header || `What's tested in the ${productName}?`}
            </UI.Heading>
            {children && (
              <Markdown
                components={{
                  p: ({ children }: { children: React.ReactNode }) => (
                    <UI.Paragraph color="white" className="mt-6">
                      {children}
                    </UI.Paragraph>
                  ),
                }}
              >
                {children}
              </Markdown>
            )}
          </div>
        </div>

        {/* Main Content */}
        <div className="relative flex-1 justify-items-center space-y-4">
          {included.map((group, index) => {
            const analysesIncluded = group.tests
              .flatMap(({ analyses }) => analyses)
              .filter((analysis) => (isEmpty(group.analyses) ? true : group.analyses?.includes(analysis.id)))

            return <TestCard key={index} title={group.name} description={group.why} measurements={analysesIncluded} />
          })}
        </div>
      </div>
    </div>
  )
}

export default ProductTestList
